import { toPairs } from 'ramda';
import kebabCase from 'lodash.kebabcase';
import isMobile from 'is-mobile';
import Endpoints from '@twnel/utils-js/lib/Endpoints';
import { withIframeQuery } from '@twnel/web-components/lib/iframe';
import { WIDGET_SIZE, WIDGET_UPDATED, WIDGET_COMPANY } from 'src/data/namespace';

const { env, company = '' } = window.twnel;
const { PWA_ORIGIN } = Endpoints(env);

const frameStyle = {
  position: 'fixed',
  top: 'initial',
  left: 'initial',
  zIndex: '2147483647',
  border: 'none',
  width: 0,
  height: 0,
  minWidth: 'initial',
  minHeight: 'initial',
  overflow: 'hidden',
  ...(isMobile() ? {
    bottom: '0.5rem',
    right: '0.5rem',
  } : {
    bottom: '1.5rem',
    right: '1.5rem',
  }),
};

const frameUpdated = ({ id }) => ({
  type: WIDGET_UPDATED,
  payload: { id },
});

const frame = window.document.createElement('iframe');
frame.allow = 'camera;microphone';
toPairs(frameStyle).forEach(([key, value]) => {
  frame.style[kebabCase(key)] = value;
});

const { hostname: pwaHostname } = new URL(PWA_ORIGIN);
window.addEventListener('message', ({ origin = '', data } = {}) => {
  const { hostname } = new URL(origin);
  if (pwaHostname !== hostname) {
    return;
  }

  if (
    data?.type === WIDGET_SIZE
    && data?.payload?.width
    && data?.payload?.height
  ) {
    frame.style.width = data.payload.width;
    frame.style.height = data.payload.height;
    frame.contentWindow.postMessage(frameUpdated({
      id: data.payload.id,
    }), PWA_ORIGIN);
  }
});

const url = new URL(PWA_ORIGIN);
url.searchParams.set(WIDGET_COMPANY, company);
frame.src = withIframeQuery(url.href);
window.document.body.appendChild(frame);
